import axios from 'axios'

const ApiHandler = axios.create({
    baseURL: '/api'
})

// Add a response interceptor
ApiHandler.interceptors.response.use(
    response => {
        // Any 200 status code
        return response.data;
    },
    error => {
        // Any status codes that falls outside the range of 200 causes this function to trigger
        return Promise.reject(error);
    }
);

export default ApiHandler;