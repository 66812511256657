import React from 'react';
import MNECCButton from "../MNECCButton"
import { formatDate, formatTime } from '../../util/DateTimeUtil';
import { HiCalendar, HiClock, HiOutlineMap } from "react-icons/hi";
import './EventDetails.css'

const EventDetails = ({event, setShowForm}) => {

    return (
        <>
            <div className='pt-3' style={{ background: '#f3f3f3' }}>
            {/*Event metadata*/}
            <div className='d-flex justify-content-between pb-4'>
              <div>
                <h4 className="event-detail-label">Date<HiCalendar /></h4>
                <em>{formatDate(event.date)}</em>
              </div>
              <div>
                <h4 className="event-detail-label">Time<HiClock /></h4>
                <em>{formatTime(event.date)}</em>
              </div>
            </div>
            <div className='d-flex justify-content-between pb-4'>
              <div>
                <h4 className="event-detail-label">Location<HiOutlineMap /></h4>
                <em>12345</em>
              </div>
              <div className='d-flex align-items-end'>
                <MNECCButton text="Signup" fn={() => setShowForm(true)} />
              </div>
            </div>
          </div>
          <div className='py-10'>
            <h4 className="event-detail-label">Description</h4>
            {event.description}
          </div>
        </>
    );
};

export default EventDetails;
