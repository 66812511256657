import React from 'react';
import MNECCToast from '../MNECCToast';

const Success = () => {

  return (
    <>
      <MNECCToast showToast={true} type="success" message="Success" />
      <h1>Success !</h1>
    </>
  );
};

export default Success;
