import React from 'react';
import CheckoutItem from '../payment/CheckoutItem';

const EventPaymentHeader = ({event}) => {

    return (
        <>
            <h3 style={{display: "block"}} className="title">Checkout</h3>
            <div className="products">
                <CheckoutItem price={event.price} productName={event.title} productDescription={event.description} />
            </div>
        </>
    );
};

export default EventPaymentHeader;
