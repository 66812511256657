import React from 'react';
import { Footer } from 'flowbite-react'

const MNECCNavbar = () => {

    return (
        <>
            <Footer container>
                <div className="w-full text-center">
                    <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                        <Footer.Brand
                            href="/"
                            src="../../img/MNEOC_bg.png"
                            alt="MNECC Logo"
                            name="MNECC"
                        />
                        <Footer.LinkGroup>
                            <Footer.Link href="/about-us">About</Footer.Link>
                            <Footer.Link href="#">Privacy Policy</Footer.Link>
                            <Footer.Link href="/#contact">Contact</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <Footer.Divider />
                    <Footer.Copyright href="https://flowbite.com" by="Flowbite™" year={2024} />
                </div>
            </Footer>
        </>
    );
};

export default MNECCNavbar;
