import React, { useState } from 'react';

import RegistrationForm from './RegistrationForm';
import EventDetails from './EventDetails';
import PaymentModal from '../PaymentModal'
import { processRegistration } from '../../util/EventRegistrationUtil';
import { Spinner } from "flowbite-react";
import MNECCToast from '../MNECCToast';

const EventForm = ({ event }) => {

  const [showForm, setShowForm] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = React.useState(false)
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Event registration failed. Please try again.");

  const onClosePaymentModal = () => {
    setShowPaymentModal(false)
  }

  const clearForm = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  const handleSubmit = async (subjet, text) => {
    setLoading(true)
    //send email and register
    const payload = {
      to: email,
      from: 'dev.nate.alleyne@gmail.com',
      subject: subjet,
      text: text,
      event: event,
      firstName: firstName,
      lastName: lastName,
    }
    try {
      const response = await processRegistration(payload)
      if (response.success) {
        setShowSuccess(true)
      }
      else {
        setErrorMsg(response.message)
        setShowError(true)
      }
    } catch (error) {
      setShowError(true)
    } finally {
      clearForm()
      setLoading(false)
      setShowForm(false)
    }
  }

  return (
    <>
      {showSuccess && <MNECCToast showToast={setShowSuccess} type="success" message={"You've been registered"} />}
      {showError && <MNECCToast showToast={setShowError} type="error" message={errorMsg} />}
      <PaymentModal show={showPaymentModal} onClose={onClosePaymentModal} type="event" price={event?.price} handleRegistration={handleSubmit} event={event} recipient={email} />
      {showForm && (
        loading ? <Spinner /> :
          <RegistrationForm event={event} setShowPaymentModal={setShowPaymentModal} handleSubmit={handleSubmit} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} />
      )}
      <EventDetails event={event} setShowForm={setShowForm} />
    </>
  );
};

export default EventForm;
