import React from "react";
import './CheckoutItem.css'

const CheckoutItem = ({ price, productName, productDescription }) => {
  return (
    <>
      <div className="item">
        <span className="item-name">{productName}</span>
        <p className="price">{`$${price}`}</p>
        <p className="item-description">{productDescription}</p>
      </div>
    </>
  );
};

export default CheckoutItem;
