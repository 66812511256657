import ApiHandler from './ApiHandler';

export const processRegistration = async (msg) => {
    if (validateEvent(msg.event)) {
        try {
            await ApiHandler.post('/register', msg)
            return {success: true}
            // window.open('/success', '_self')
        } catch (error) {
            return Promise.reject(error);
        }
    } else {
        return {success: false, message: "Cannot register for this event."}
    }
}

const validateEvent = (event) => {
    return new Date(event.date).getTime() > new Date().getTime()
}