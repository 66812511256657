import React from 'react';
import { Button, Form } from 'react-bootstrap'
import './RegistrationForm.css'

const RegistrationForm = ({ event, setShowPaymentModal, handleSubmit, setFirstName, setLastName, setEmail }) => {

  const handleClick = () => {
    if (event.price > 0) {
      setShowPaymentModal(true)
      return
    }
    handleSubmit("You're registered", `Event ${event.title}`)
  }

  return (
    <>
      <div>
        <Form.Group>
          <Form.Control className="form-input" type="text" name="fName" id="fName" placeholder="First Name" required onChange={e => setFirstName(e.target.value)} />
          <Form.Control className="form-input" type="text" name="lName" id="lName" placeholder="Last Name" required onChange={e => setLastName(e.target.value)} />
          <Form.Control className="form-input" type="email" name="email" id="email" placeholder="Email" required onChange={e => setEmail(e.target.value)} />
        </Form.Group>
        <div className='d-flex justify-content-end my-2'>
          <Button onClick={handleClick}>{event.price > 0 ? "Checkout" : "Register"}</Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
