import React, { useState } from 'react';
import '../home/EventSection.css'
import { Card, Col, Row } from 'react-bootstrap';
import AirtableClient from '../../util/AirtableClient';
import './Events.css'
import MNECCNavbar from '../MNECCNavbar';
import MNECCFooter from '../MNECCFooter'
import EventModal from './EventModal';

const EVENTS = await AirtableClient.get('/Events')

const Events = () => {
    const [selectedEvent, setselectedEvent] = useState(null);
    const [show, setShow] = useState(false);

    const handleLinkClick = (e, event) => {
        e.preventDefault()
        setselectedEvent(event)
        setShow(true)
    }

    const onClose = () => {
        setShow(false)
    }

    return (
        <>
            {selectedEvent && (
                <EventModal show={show} onClose={onClose} event={selectedEvent} />
            )}
            <MNECCNavbar />
            <div className="flex flex-col min-h-screen">
                <div className="flex-grow all-events-container">
                    <h1 style={{ textAlign: 'center' }}>All Events</h1>
                    <div className="all-events col-md-6" style={{ margin: 'auto' }}>
                        <div>
                            <Row xs={1} md={2} lg={4} xl={4}>
                                {EVENTS.map(event => (
                                    <Col key={event.id} className="mb-4 d-flex justify-content-center">
                                        <Card style={{ marginBottom: '1rem' }}>
                                            <Card.Img style={{height: "140px"}} variant="top" src={event.photo ? event.photo[0].url : "https://bucketeer-86a0bc3d-743c-466b-b14c-5dcb3c1d5823.s3.amazonaws.com/public/event_placeholder_img.jpg"} alt={event.title} />
                                            <Card.Body>
                                                <Card.Title>{event.title}</Card.Title>
                                                <Card.Text>
                                                    {event.description}
                                                    &nbsp;
                                                    <a onClick={(e) => handleLinkClick(e, event)} style={{ textDecoration: 'none' }} href={event.url ? event.url : "/"} target="_blank" rel="noreferrer">
                                                        Read More
                                                    </a>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
                <MNECCFooter />
            </div>
        </>
    );
};

export default Events;
