import React from 'react';
import './AboutUs.css'
import Officer from './Officer';
import MNECCNavbar from '../MNECCNavbar'
import MNECCFooter from '../MNECCFooter'
import AirtableClient from '../../util/AirtableClient'

const BIOS = await AirtableClient.get('/Bios')

const AboutUs = () => {

  return (
    <>
      <MNECCNavbar />
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <div className='title'>
            <h1 className="section-title lg-line" style={{ textAlign: 'center' }}>Meet the Board</h1>
            <hr className="botm-line yellow-line" />
          </div>

          <div className='bios'>
            {
              BIOS.map(bio => {
                return <Officer officer={bio} />
              })
            }
          </div>
        </div>
        <MNECCFooter />
      </div>
    </>
  );
};

export default AboutUs;
