// Modal.js
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import './PaymentModal.css'
import MNECCToast from './MNECCToast'
import DonationHeader from './payment/DonationHeader';
import EventPaymentHeader from './payment/EventPaymentHeader';
import { processPayment, sendConfirmationEmail, sendReceipt } from '../util/PaymentUtil';
import PaymentOptions from './payment/PaymentOptions';

const PaymentModal = ({ show, onClose, type, price, handleRegistration, event, recipient }) => {
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [amount, setAmount] = useState(price | 0);
    const [email, setEmail] = useState(recipient);

    const handlePayment = async (token) => {
        const paymentResponse = await processPayment(token.token, amount)
        if (paymentResponse.success) {
            setShowSuccessToast(true)
            if (type === "event") {
                await handleRegistration("You're registered", `Event ${event.title}`)
                await sendReceipt(event, paymentResponse, recipient)
            } else {
                await sendConfirmationEmail(email, type)
            }
    }
        else {
            setShowErrorToast(true)
        }
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Body style={{padding: "0"}}>
                <div className="modalHeader">
                {showSuccessToast && (
                    <MNECCToast showToast={setShowSuccessToast} type="success" message="Payment processed successfully." />
                )}
                {showErrorToast && (
                    <MNECCToast showToast={setShowErrorToast} type="error" message="error with payment" />
                )}
                {type === "donation" && <DonationHeader setAmountDonate={setAmount} setEmail={setEmail} />}
                {type === "event" && <EventPaymentHeader event={event} />}
                </div>
                <div className="modalBody">
                    <h3 style={{display: "block", marginBottom: "0", borderBottom: "none"}} className="title">Payment Options</h3>
                    <hr style={{margin: "0"}} className="botm-line" />
                    <PaymentOptions amount={amount} handlePayment={handlePayment} />
                    <Button className="float-right" variant="danger" onClick={onClose}>Close</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PaymentModal;
