import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './MNECCNavbar.css'

const MNECCNavbar = () => {

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand style={{color:'whitesmoke'}} href="/">MNECC</Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link style={{color:'whitesmoke'}}  href="/">Home</Nav.Link>
                        <Nav.Link style={{color:'whitesmoke'}} href="/about-us">About</Nav.Link>
                        <Nav.Link style={{color:'whitesmoke'}} href="/events">Events</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default MNECCNavbar;
