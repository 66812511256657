import React, { useState } from 'react';
import EventCalendar from '../events/EventCalendar'
import AirtableClient from '../../util/AirtableClient';
import { format } from 'date-fns'
import './EventSection.css'
import { Carousel } from 'react-bootstrap';
import EventModal from '../events/EventModal';

const EVENTS = await AirtableClient.get('/Events')

const EventSection = () => {
    const [selectedEvent, setselectedEvent] = useState(null);
    const [show, setShow] = useState(false);

    const onClose = () => {
        setShow(false)
    }

    const isFutureEvent = (eventDate) => {
        const currentDate = new Date();
        return new Date(eventDate) > currentDate;
    };

    const futureEvents = EVENTS.filter(event => isFutureEvent(event.date));

    return (
        <>
            <div className="heading">
                <h2 className="ser-title">Upcoming Events</h2>
                <hr style={{ margin: "unset" }} className="botm-line" />
            </div>
            <div className="event-section justify-content-center">
                {selectedEvent && (
                    <EventModal show={show} onClose={onClose} event={selectedEvent} />
                )}
                <div className='col-md-6 event-content-container'>
                    <>
                        {/* Link to All Events */}
                        <div className="col-md-12 text-center mb-2">
                            <a href="/events" className="all-events-link">All Events</a>
                        </div>
                        <Carousel>
                            {futureEvents.map(event => {
                                return (
                                    <Carousel.Item key={event.id} onClick={() => { setselectedEvent(event); setShow(true) }}>
                                        <img
                                            className="d-block w-100"
                                            src={event?.photo[0].url || "https://flowbite.com/docs/images/carousel/carousel-1.svg"}
                                            alt={event.title}
                                            style={{ maxHeight: '480px' }}
                                        />
                                        <Carousel.Caption>
                                            <h3>{event.title}</h3>
                                            <p>{event.description}</p>
                                            <button
                                                onClick={() => {
                                                    setselectedEvent({
                                                        title: event.title,
                                                        description: event.description,
                                                        date: format(
                                                            new Date(event.date),
                                                            'PP'
                                                        )
                                                    });
                                                    setShow(true)
                                                }}
                                            >
                                                View Details
                                            </button>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </>
                </div>
                <div className='col-md-6 align-items-center'>
                    <h3 className="text-center">Event Calendar</h3>
                    <EventCalendar events={EVENTS} setSelectedCalendarEvent={setselectedEvent} setShowEventModal={setShow} />
                </div>
            </div>
        </>
    );
};

export default EventSection;
