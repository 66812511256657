import React, { useState } from 'react';
import PaymentModal from '../PaymentModal';
import './ContactSection.css';

const ContactSection = () => {

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="ser-title">Contact us</h2>
                    <hr className="botm-line" />
                </div>
                <div className="col-md-4 col-sm-4">
                    <h3>Contact Info</h3>
                    <div className="space"></div>
                    <p><i className="fa fa-map-marker fa-fw pull-left fa-2x"></i>822 E Chestnut Street<br /> Coatesville, PA 19320</p>
                    <div className="space"></div>
                    <p><i className="fa fa-envelope-o fa-fw pull-left fa-2x"></i>gyle001@hotmail.com</p>
                    <div className="space"></div>
                    <p><i className="fa fa-phone fa-fw pull-left fa-2x"></i>610-380-6193</p>
                    <div style={{ width: '100%' }}><iframe title='map' width="200" height="140" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=824%20E%20Chestnut%20Street,%20Coatesville%20PA%2019320+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.google.com/maps/search/?api=1&query=824%20E%20Chestnut%20Street,%20Coatesville%20PA%2019320">measure distance on map</a></iframe></div>
                </div>
                <div className="col-md-8 col-sm-8 marb20">
                    <div className="contact-info">
                        <h3 className="cnt-ttl">Subscribe to our mailing list!</h3>
                        <div className="space"></div>
                        <div id="sendmessage">Your message has been sent. Thank you!</div>
                        <div id="errormessage"></div>
                        <form action="/signup" method="POST" className="contactForm">
                            <div className="form-group">
                                <input type="text" name="fname" className="form-control br-radius-zero" id="fname" placeholder="Your First Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                                <input type="text" name="lname" className="form-control br-radius-zero" id="lname" placeholder="Your Last Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control br-radius-zero" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                <div className="validation"></div>
                            </div>
                            <div className="form-action">
                                <button type="submit" className="button">Subscribe</button>
                            </div>
                        </form>
                        <div className='mt-10'>
                            <div className="row">
                                <div className="col-md-12 justify-content-center">
                                    <h2 className="ser-title">Contribute</h2>
                                    <hr className="botm-line" />
                                    <button onClick={() => setShowModal(true)} style={{
                                        background: 'none',
                                        color: 'white',
                                        border: 'none',
                                        padding: '0',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}>Make a Difference with Your Donation</button>

                                </div>
                            </div>
                            <div>
                                <PaymentModal show={showModal} onClose={toggleModal} type="donation" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactSection;
