import ApiHandler from './ApiHandler';

export const processPayment = async (token, amount) => {
    try {
        return await ApiHandler.post('/square-payment', {
            payload: {
                sourceId: token,
                amount
            }
        })
    } catch (error) {
        console.log('error with payment: ', error)
        return error
    }
}

export const sendConfirmationEmail = async (email, type) => {
    const msg = {
        to: email,
        from: 'dev.nate.alleyne@gmail.com',
        subject: 'Donation to MNEOC',
        text: "Thank you for your donation :)",
    }
    
    if (type === "event") {
        const updatedMessage = addEventDetailsToMessage(msg)
        console.log(updatedMessage)
    }

    const emailResponse = await ApiHandler.post('/email', msg)
    if (!emailResponse.ok) {
        throw new Error(emailResponse.message);
    }
}

export const sendReceipt = async (event, payment, email) => {
    const msg = {
        to: email,
        from: 'dev.nate.alleyne@gmail.com',
        subject: `Receipt for your purchase: ${event.title}`,
        event,
        payment
    }

    const emailResponse = await ApiHandler.post('/receipt', msg)
    if (!emailResponse.ok) {
        throw new Error(emailResponse.message);
    }
}

const addEventDetailsToMessage = async (message) => {
    return message;
}