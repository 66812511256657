import React, { useState } from 'react';
import { Avatar, Card } from "flowbite-react";
import { useCollapse } from 'react-collapsed';
import './Officer.css'

const Officer = ({ officer }) => {

    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    return (
        <>
            <Card className="max-w-sm">
                <div className="flex flex-col items-center pb-10">
                    <div className="avatar">
                        <Avatar {...getToggleProps({
                            onMouseEnter: () => setExpanded((prevExpanded) => !prevExpanded),
                            onMouseLeave: () => setExpanded((prevExpanded) => !prevExpanded)
                        })} img={officer.photo[0].url} size="xl" rounded />
                    </div>
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{officer.officerName}</h5>
                    <section className="bio-section" {...getCollapseProps()}>
                        <span className="text-sm text-gray-500 dark:text-gray-400">{officer.officerBio}</span>
                    </section>
                </div>
            </Card>
        </>
    );
};

export default Officer;
