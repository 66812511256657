import React, { useState, useRef } from "react";
import { Tabs } from "flowbite-react";
import { HiCreditCard, HiCash } from "react-icons/hi";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'

const PaymentOptions = ({ amount, handlePayment }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  return (
    <>
      {/* eslint-disable-next-line */}
      <Tabs aria-label="Tabs with underline" style="underline" ref={tabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
        <Tabs.Item title="Card" icon={HiCreditCard} />
        <Tabs.Item title="Cash App" icon={HiCash} />
      </Tabs>
      <PaymentForm
        applicationId={process.env.REACT_APP_SQUARE_APP_ID}
        locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
        cardTokenizeResponseReceived={handlePayment}
      >
        {activeTab === 0 && <CreditCard children={`Pay $${amount}`} />}
        {activeTab === 1 && (
          <div className="payment-container" id="cash-app-pay">
            not currently supported
          </div>
        )}
      </PaymentForm>
    </>
  );
};

export default PaymentOptions;
