import React from 'react';
import './MainSection.css'

const MainSection = () => {

    return (
        <div className="bg-color">
            <div className="container">
                <div className="row">
                    <div className="banner-info">
                        <div className="banner-logo text-center">
                            <img alt="logo" src="img\MNEOC_bg_wht.png" width="390px" height="340px" className="img-responsive" />
                        </div>
                        <div className="banner-text text-center">
                            <h1 className="white">Our Vision</h1>
                            <p>Professional community resource promoting healthier lifestyle choices through education and engaging with community stakeholders</p>
                        </div>
                        <div className="overlay-detail text-center">
                            <a href="#photos"><i className="fa fa-angle-down"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainSection;
