import axios from 'axios'

const ACCESS_TOKEN = process.env.REACT_APP_AIRTABLE_ACCESS_TOEKN;
const BASE_ID = 'appSf43lGQTCjSSRC';

const AirtableClient = axios.create({
    baseURL: `https://api.airtable.com/v0/${BASE_ID}`,
    headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
})

// Add a response interceptor
AirtableClient.interceptors.response.use(
  (response) => {
    // Any 200 status code
    const records = response.data.records || response.data;
    return serializeRecords(records);
  },
  (error) => {
    // Any status codes that falls outside the range of 200 causes this function to trigger
    return Promise.reject(error);
  }
);

const serializeRecords = (records) => {
  if (Array.isArray(records)) {
    return records.map((record) => {
      const { id, fields } = record;
      return { ...fields, airtableRecordId: id };
    });
  } else {
    const { id, fields } = records;
    return { ...fields, airtableRecordId: id };
  }
};
  

export default AirtableClient;