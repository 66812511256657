import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/Home';
import Events from './components/events/Events';
import AboutUs from './components/about-us/AboutUs';
import Success from './components/success/Success';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/success" element={<Success />} />
        </Routes>
    </Router>
  );
}

export default App;
