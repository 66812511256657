import React from 'react';
import { Modal, Form, InputGroup } from 'react-bootstrap'

const DonationHeader = ({setAmountDonate, setEmail}) => {

    return (
        <>
            <Modal.Title>Donate</Modal.Title>
                <InputGroup>
                    <Form.Control type="text" name="fName" id="fName" placeholder="First Name" />
                    <Form.Control type="text" name="lName" id="lName" placeholder="Last Name" />
                    <Form.Control type="email" name="email" id="email" placeholder="Email" required onChange={e => setEmail(e.target.value)} />
                </InputGroup>
                <Form.Label htmlFor="amountDonate">Enter an amount</Form.Label>
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                        type="number"
                        id="amountDonate"
                        aria-describedby="donate amount"
                        onChange={e => setAmountDonate(e.target.value)}
                    />
                </InputGroup>
        </>
    );
};

export default DonationHeader;
