import React from 'react';
import './MNECCButton.css'

const MNECCButton = ({ text, fn }) => {

    return (
        <button className="button" onClick={fn}>{text}</button>
    );
};

export default MNECCButton;
