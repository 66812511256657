import React from 'react';
import { Button, Modal } from 'react-bootstrap'
import EventForm from './EventForm';
import { formatWeekDay } from '../../util/DateTimeUtil';

const EventModal = ({ show, onClose, event }) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <h1>{event.title}</h1>
        <span style={{fontSize: "12px"}}>{formatWeekDay(event.date)}</span>
      </Modal.Header>
      <Modal.Body>
        <img
          className="d-block w-100"
          src={event?.photo[0].url}
          alt={event.title}
        />
        <EventForm event={event} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="float-right" variant="danger" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
