import React from 'react';
import './AboutSection.css';

const AboutSection = () => {

    return (
        <div className="container">
            <div className="about-section-container">
                <div className="about-section-photo-container ">
                    <img className="about-section-photo" src="img\team-hands-linked-together.jpg" alt="hands together" />
                </div>
                <div className="" style={{ color: '#999' }}>
                    <a style={{ textDecorationColor: '#fff' }} href="/about-us" ><h2 className="section-title white lg-line" style={{ textAlign: 'center', marginBottom: '20px' }}>About Us</h2></a>
                    <hr className="botm-line" />
                    <p>The Minority Nurse Educators of Chesco (MNECC), an auxiliary of W.C. Atkinson Memorial Community
                        Service Center Inc., a Coatesville non-profit, are local RNs and LPNs dedicated to promote and
                        improve health care outcomes throughout Chester County; with a primary focus in Coatesville and
                        surrounding communities.
                    </p>
                    <p>
                        The MNECC collectively have many years of healthcare experience in a variety of practical and
                        leadership roles. We have joined together as nurses in a unified effort to serve our community
                        through health education, screening, and advocacy. We will identify and address gaps and
                        barriers to health care and seek to bring about solutions through community collaboratives
                        and partnerships. We will be a listening ear, thoughtful in our approach, respectful in every
                        interaction with ongoing evaluation of effectiveness. We will provide updated health care
                        information utilizing CDC, state, local guidelines, and mandates.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;
