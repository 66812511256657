import React, { useState, useEffect } from 'react';
import { Toast } from 'flowbite-react'
import { HiCheck, HiX, HiExclamation } from "react-icons/hi";
import { Progress } from "flowbite-react";

const MNECCToast = ({ showToast, type, message }) => {

    const getToastClass = (type) => {
        const val = "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg "
        if (type === "success") {
            return val + "bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
        }
        else if (type === "warning") {
            return val + "bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200"
        }
        else {
            return val + "bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200"
        }
    }
    const toastClass = getToastClass(type);

    const icons = {
        success: <HiCheck className="h-5 w-5" />,
        error: <HiX className="h-5 w-5" />,
        warning: <HiExclamation className="h-5 w-5" />
    };

    const [progress, setProgress] = useState(100);
    useEffect(() => {
        // Calculate the interval time to fill the bar in 5 seconds
        const intervalTime = 50; // milliseconds
        const totalIntervalCount = 2500 / intervalTime; // Total intervals in 5 seconds
        const progressIncrement = 100 / totalIntervalCount; // Increment to fill in each interval

        // Set up an interval to update the progress
        const intervalId = setInterval(() => {
            setProgress((currentProgress) => {
                const updatedProgress = currentProgress - progressIncrement;
                if (updatedProgress <= 0) {
                    clearInterval(intervalId); // Clear interval when progress is complete
                    showToast(false)
                    return 0; // Cap the progress at 0%
                }
                return updatedProgress;
            });
        }, intervalTime);

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    });

    return (
        <Toast style={{position: "absolute", zIndex: "1"}}>
            <div style={{ width: "-webkit-fill-available" }}>
                <div className='d-flex mb-6'>
                    <div className={toastClass}>
                        {icons[type]}
                    </div>
                    <div className="ml-3 text-sm font-normal">{message}</div>
                    <Toast.Toggle />
                </div>
                <div>
                    <Progress progress={progress} size="sm" color="gray" style={{ display: "contents" }} />
                </div>
            </div>
        </Toast>
    );
};

export default MNECCToast;
