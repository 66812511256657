import React from "react";
import ApiHandler from "../../util/ApiHandler";
const PHOTOS = await ApiHandler.get("/images");

const PhotoSection = () => {
  return (
    <div className="py-5">
      <h3 className="text-center">Photos</h3>
      <div
        id="carouselExampleIndicators"
        className="carousel slide mw-555"
        data-ride="carousel"
      >
        <ol className="carousel-indicators"></ol>
        <div className="carousel-inner">
          {/* loop through s3/slideshow */}
          {PHOTOS.map((img, i) => (
            <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
              <img
                className="d-block w-100"
                style={{ width: "612px", height: "450px" }}
                src={img}
                alt={`Slide ${i}`}
              />
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default PhotoSection;
