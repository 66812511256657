import React from 'react';

import './Home.css';
import MainSection from './MainSection';
import AboutSection from './AboutSection';
import PhotoSection from './PhotoSection';
import ContactSection from './ContactSection';
import EventSection from './EventSection';


const Home = () => {

    return (
        <>
            <section id="banner">
                <MainSection />
            </section>
            <section id="about" className="section-padding">
                <AboutSection />
            </section>
            <section id="events" className="section-padding">
                <EventSection />
            </section>
            <section id="photos" className="d-flex justify-content-center">
                <PhotoSection />
            </section>
            <section id="contact" className="section-padding">
                <ContactSection />
            </section>
        </>
    )
};

export default Home;
